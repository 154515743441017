.home {
  width: 100%;
  font-family: "Arial", sans-serif;
  color: #2C3E50;
}

.about {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #2C3E50;
  color: #f0f0f0;
}

.pic-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-pic {
  border-radius: 50%;
  width: 250px;
  margin-top: 2.5rem;
}

.about h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 80px;
  color: #e9d35b;
  height: 50px;
  position: relative;
}

.about .prompt {
  width: 40%;
  font-size: 30px;
  position: relative;
  /* bottom: 3.5rem; */
}
.prompt svg {
  font-size: 55px;
  margin: 5px;
  color: white;
  transition: transform 0.2s ease-in-out;
}

.prompt svg:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.mycv-btn {
  margin-top: 3rem;
}

.skills {
  color: #2C3E50;
  font-size: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.skills h1 {
  color: #2C3E50;
  position: relative;
  top: 2.5rem;
}

.skills h2 {
  font-size: 40px;
}

.skills .list {
  list-style: none;
  width: 60%;
  position: relative;
  bottom: 20px;
}

.list span {
  font-size: 25px;
  position: relative;
  bottom: 15px;
  padding-left: 15px;
}

@media only screen and (max-width: 600px) {
  .about {
    height: calc(105vh - 105px);
  }
  .my-pic {
    width: 300px;
  }

  .about h2 {
    font-size: 30px;
    margin-top: 2rem;
  }

  .about .prompt {
    font-size: 18px;
    width: 80%;
    position: relative;
    bottom: 2rem;
  }

  .prompt svg {
    position: relative;
  }

  .skills {
    text-align: center;
  }
  .list {
    padding: 0;
  }

  .skills h2 {
    font-size: 30px;
  }
}