.project {
  color:#2C3E50;
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.project h1 {
  color: #2C3E50;
  font-size: 35px;
  position: relative;
  top: 3.3rem;
}
.project img {
  width: 700px;
  border-radius: 10px;
  position: relative;
  top: 4rem;
}
.project .description {
  font-size: 20px;
  color: #2C3E50;
  width: 50%;
  text-align: center;
  font-style: italic;
  position: relative;
  top: 5.5rem;
}

.project .skills {
  font-size: 30px;
  color: #2C3E50;
  position: relative;
  top: 5rem;
}

.project svg {
  font-size: 60px;
  color: #2C3E50;
  position: relative;
  top: 5rem;
  transition: transform 0.2s ease-in-out;
}

.project svg:hover {
  transform: scale(1.2);
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .project {
    width: 100%;
    height: calc(55vh - 55px);
    position: relative;
    top: 35px;
  }
  .project h1 {
    color: #2C3E50;
    font-size: 20px;
    position: relative;
    top: 3rem;
  }
  .project img {
    width: 100%;
    border-radius: 10px;
  }
  .project .description {
    font-size: 14px;
    color: #2C3E50;
    text-align: center;
    width: 75%;
    position: relative;
    top: 5rem;
  }
  .project .skills {
    font-size: 18px;
    color: #2C3E50;
    position: relative;
    top: 5rem;
  }
  .project svg {
    font-size: 40px;
    color: #2C3E50;
    position: relative;
    top: 5rem;
    transition: transform 0.2s ease-in-out;
  }
  
  .project svg:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
}
